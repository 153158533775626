var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-modal', {
    attrs: {
      "id": "modal-osi-flight",
      "title": _vm.$t('reservation.osi.title'),
      "title-class": "text-airline font-medium-4 font-weight-bolder",
      "centered": "",
      "no-close-on-backdrop": "",
      "no-close-on-esc": "",
      "size": "lg"
    },
    on: {
      "show": _vm.handleShowModal
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn(_ref) {
        var close = _ref.close;
        return [_c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(186, 191, 199, 0.15)',
            expression: "'rgba(186, 191, 199, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "center rounded-pill width-100",
          attrs: {
            "size": "md",
            "variant": "outline-secondary"
          },
          on: {
            "click": function click($event) {
              return close();
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('reservation.close')) + " ")]), _c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(113, 102, 240, 0.15)',
            expression: "'rgba(113, 102, 240, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "btn-gradient mt-lg-25  border-0",
          attrs: {
            "disabled": !_vm.osiContent || !_vm.passengersSelected || !_vm.passengersSelected.length,
            "pill": ""
          },
          on: {
            "click": _vm.sendOSIFlightHandle
          }
        }, [_c('span', {
          staticClass: "align-middle"
        }, [_vm._v(_vm._s(_vm.$t('reservation.osi.title')))])])];
      }
    }])
  }, [_c('div', {
    staticStyle: {
      "max-height": "70vh",
      "overflow-y": "auto"
    }
  }, [_c('div', {
    staticClass: "mb-50"
  }, [_c('div', {
    staticClass: "font-medium-4 fw-700"
  }, [_vm._v(" " + _vm._s(_vm.$t('reservation.bookingCode')) + ": "), _c('span', {
    staticClass: "text-info text-uppercase"
  }, [_vm._v(_vm._s(_vm.bookingData.bookingCode))])]), _c('div', {
    staticClass: "text-warning font-italic"
  }, [_c('div', [_vm._v("(*) Lựa chọn dich vụ cần thêm, và remark")]), _c('div', [_vm._v("(**) Lựa chọn hành khách cần thêm")]), _c('div', [_vm._v("(***) bạn sẽ chịu trách nhiệm bồi thường cho hãng nếu thông tin sai")])])]), _c('b-form-group', {
    attrs: {
      "label-for": "osiCode"
    },
    scopedSlots: _vm._u([{
      key: "label",
      fn: function fn() {
        return [_c('span', {
          staticClass: "text-heading-5"
        }, [_vm._v(_vm._s(_vm.$t('reservation.osi.chooseOsiCode')))]), _c('span', {
          staticClass: "text-danger"
        }, [_vm._v("(*)")])];
      },
      proxy: true
    }])
  }, [_c('v-select', {
    attrs: {
      "id": "osiCode",
      "taggable": "",
      "options": _vm.osiCodeOptions,
      "reduce": function reduce(val) {
        return val.value;
      },
      "label": "value",
      "filterable": "",
      "input-id": "osiCode",
      "placeholder": _vm.$t('reservation.osi.chooseOsiCode')
    },
    scopedSlots: _vm._u([{
      key: "option",
      fn: function fn(item) {
        return [_vm._v(" " + _vm._s(item.value) + " - " + _vm._s(item.text) + " ")];
      }
    }, {
      key: "selected-option",
      fn: function fn(item) {
        return [_vm._v(" " + _vm._s(item.value) + " - " + _vm._s(item.text) + " ")];
      }
    }, {
      key: "no-options",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.$t('noOptions')) + " ")];
      },
      proxy: true
    }]),
    model: {
      value: _vm.osiCode,
      callback: function callback($$v) {
        _vm.osiCode = $$v;
      },
      expression: "osiCode"
    }
  })], 1), _c('b-form-group', {
    scopedSlots: _vm._u([{
      key: "label",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.$t('reservation.osi.text')) + " ")];
      },
      proxy: true
    }])
  }, [_c('b-form-input', {
    staticStyle: {
      "font-size": "16px",
      "line-height": "24px"
    },
    attrs: {
      "lazy-formatter": "",
      "formatter": _vm.removeAccentsUpperCaseFormatter,
      "placeholder": _vm.$t('reservation.osi.placeholderRemark')
    },
    model: {
      value: _vm.osiContent,
      callback: function callback($$v) {
        _vm.osiContent = $$v;
      },
      expression: "osiContent"
    }
  })], 1), _c('div', {
    staticClass: "text-heading-5 mb-25"
  }, [_vm._v(" " + _vm._s(_vm.$t('reservation.specialServices.passengers.title')) + " ")]), _c('b-form-group', {
    staticClass: "margin-checkbox-label mb-0",
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var ariaDescribedby = _ref2.ariaDescribedby;
        return [_c('b-form-checkbox-group', {
          staticClass: "mb-1",
          attrs: {
            "aria-describedby": ariaDescribedby,
            "stacked": "",
            "name": "passengers"
          },
          model: {
            value: _vm.passengersSelected,
            callback: function callback($$v) {
              _vm.passengersSelected = $$v;
            },
            expression: "passengersSelected"
          }
        }, _vm._l(_vm.bookingData.paxLists, function (passenger, indexPassenger) {
          return _c('div', {
            key: indexPassenger,
            staticClass: "d-flex flex-column my-25"
          }, [_c('b-form-checkbox', {
            attrs: {
              "value": passenger
            }
          }, [_c('div', {
            staticClass: "d-flex align-items-center"
          }, [_c('div', {
            staticClass: "mr-50 text-heading-4"
          }, [_vm._v(" " + _vm._s("".concat(passenger.paxId, " ").concat(passenger.lastName, " ").concat(passenger.firstName, " ").concat(passenger.title)) + " ")])])])], 1);
        }), 0)];
      }
    }])
  }), _c('AppCollapse', {
    staticClass: "border-dark rounded-8px",
    attrs: {
      "type": "card"
    }
  }, [_c('AppCollapseItem', {
    attrs: {
      "title": "",
      "class-header": "card-header bg-blue-gradient"
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('span', {
          staticClass: "font-weight-bolder text-info"
        }, [_vm._v(" Khái niệm VIP và CIP ")]), _c('small', [_vm._v("Khi bạn phục vụ cho đối tượng khách hàng là VIP/CIP cần thông báo cho Hãng để được phục vụ tốt nhất. Đây là quy định bắt buộc!")])];
      },
      proxy: true
    }])
  }, [_c('div', [_c('div', {
    staticClass: "text-danger"
  }, [_vm._v(" Khách VIP là những nhân vật quan trọng. Phân loại khách VIP theo quy định phục vụ hành khách của Vietnam Airlines như sau: ")]), _c('li', {
    staticClass: "text-danger fw-600"
  }, [_vm._v(" Khách VIP 1: ")]), _c('div', {
    staticClass: "text-justify"
  }, [_vm._v(" Tổng bí thư; Chủ tịch nước; phó Chủ tịch nước; Thủ tướng; phó Thủ tướng Chính phủ; Chủ tịch quốc hội; phó Chủ tịch quốc hội; Ủy viên bộ chính trị; Ủy viên Ban Bí thư và các đồng chí đã từng giữ chức vụ trên. ")]), _c('li', {
    staticClass: "text-danger fw-600"
  }, [_vm._v(" Khách VIP 2: ")]), _c('div', {
    staticClass: "text-justify"
  }, [_vm._v(" Bộ trưởng/cấp tương đương Bộ trưởng; Đại biểu Quốc hội trong nhiệm kỳ; Bí thư/Chủ tịch UBND/Chủ tịch HĐND cấp tỉnh, thành phố trực thuộc Trung ương; Bí thư Đảng uỷ khối ở Trung ương; Đại tướng;Ủy viên Ban chấp hành Trung ương đảng; Chủ nhiệm các uỷ ban của Quốc hội; Ủy viên Ủy ban Thường vụ Quốc hội; Ủy viên Hội đồng Quốc phòng và An ninh; Ủy viên Quân ủy Trung ương; Ủy viên Đảng ủy Công an Trung ương; Tổng tham mưu trưởng quân đội Nhân dân Việt Nam; Chủ nhiệm Tổng cục Chính trị Quân đội Nhân dân Việt. ")]), _c('li', {
    staticClass: "text-danger fw-600"
  }, [_vm._v(" Khách VIP 3: ")]), _c('div', {
    staticClass: "text-justify"
  }, [_vm._v(" Thứ trưởng/cấp tương đương Thứ trưởng; Phó Bí thư/Phó Chủ tịch UBND/Phó Chủ tịch HĐND cấp tỉnh/thành phố trực thuộc Trung ương; Phó Bí thư Đảng uỷ khối ở Trung ương; Phó chủ nhiệm các uỷ ban của Quốc hội; Phó Tổng tham mưu trưởng Quân đội nhân dân Việt Nam; Phó Chủ nhiệm Tổng cục Chính trị Quân đội Nhân dân Việt Nam; Chính ủy/ Phó Chính ủy quân khu/quân chủng/bộ đội biên phòng/cảnh sát biển/tư lệnh quân khu/quân chủng/bộ đội biên phòng/cảnh sát biển; Thiếu tướng các lực lượng vũ trang trở lên; Đại sứ đặc mệnh toàn quyền Việt Nam tại các nước, Đại sứ đặc mệnh toàn quyền các nước tại Việt Nam. ")]), _c('div', {
    staticClass: "text-danger"
  }, [_vm._v(" Dịch vụ CIP thường dành cho các lãnh đạo doanh nhân quan trọng, đối tác thương mại của hãng. ")])])])], 1)], 1)]), _c('ModalShowText', {
    attrs: {
      "text": _vm.responseCommand,
      "title-modal": _vm.$t('reservation.osi.title'),
      "id-modal": _vm.idModal
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }