<template>
  <div>
    <!-- SECTION modal-osi-flight -->
    <b-modal
      id="modal-osi-flight"
      :title="$t('reservation.osi.title')"
      title-class="text-airline font-medium-4 font-weight-bolder"
      centered
      no-close-on-backdrop
      no-close-on-esc
      size="lg"
      @show="handleShowModal"
    >
      <div style="max-height: 70vh; overflow-y: auto">
        <div class="mb-50">
          <div class="font-medium-4 fw-700">
            {{ $t('reservation.bookingCode') }}:
            <span class="text-info text-uppercase">{{ bookingData.bookingCode }}</span>
          </div>

          <div class="text-warning font-italic">
            <div>(*) Lựa chọn dich vụ cần thêm, và remark</div>
            <div>(**) Lựa chọn hành khách cần thêm</div>
            <div>(***) bạn sẽ chịu trách nhiệm bồi thường cho hãng nếu thông tin sai</div>
          </div>
        </div>

        <!-- ANCHOR osiCode -->
        <b-form-group label-for="osiCode">
          <template #label>
            <span class="text-heading-5">{{ $t('reservation.osi.chooseOsiCode') }}</span>
            <span class="text-danger">(*)</span>
          </template>
          <v-select
            id="osiCode"
            v-model="osiCode"
            taggable
            :options="osiCodeOptions"
            :reduce="(val) => val.value"
            label="value"
            filterable
            input-id="osiCode"
            :placeholder="$t('reservation.osi.chooseOsiCode')"
          >
            <template #option="item">
              {{ item.value }} - {{ item.text }}
            </template>

            <template #selected-option="item">
              {{ item.value }} - {{ item.text }}
            </template>

            <template #no-options>
              {{ $t('noOptions') }}
            </template>
          </v-select>
        </b-form-group>

        <b-form-group>
          <template #label>
            {{ $t('reservation.osi.text') }}
          </template>
          <b-form-input
            v-model="osiContent"
            style="font-size: 16px; line-height: 24px;"
            lazy-formatter
            :formatter="removeAccentsUpperCaseFormatter"
            :placeholder="$t('reservation.osi.placeholderRemark')"
          />
        </b-form-group>

        <!-- ANCHOR Passengers -->
        <div class="text-heading-5 mb-25">
          {{ $t('reservation.specialServices.passengers.title') }}
        </div>

        <b-form-group class="margin-checkbox-label mb-0">
          <!-- <template #label>
            <b-form-checkbox
              v-if="bookingData.paxLists.length > 1"
              v-model="passengersAllSelected"
              aria-describedby="flights"
              aria-controls="flights"
              @change="passengersToggleAll"
            >
              <div class="text-body-2">
                {{ passengersAllSelected ? $t('reservation.unselectAll') : $t('reservation.selectAll') }}
              </div>
            </b-form-checkbox>
          </template> -->
          <template v-slot="{ ariaDescribedby }">
            <b-form-checkbox-group
              v-model="passengersSelected"
              :aria-describedby="ariaDescribedby"
              stacked
              name="passengers"
              class="mb-1"
            >
              <div
                v-for="(passenger, indexPassenger) in bookingData.paxLists"
                :key="indexPassenger"
                class="d-flex flex-column my-25"
              >
                <b-form-checkbox
                  :value="passenger"
                >
                  <div class="d-flex align-items-center">
                    <div class="mr-50 text-heading-4">
                      {{ `${passenger.paxId} ${passenger.lastName} ${passenger.firstName} ${passenger.title}` }}
                    </div>
                  </div>
                </b-form-checkbox>
              </div>
            </b-form-checkbox-group>
          </template>
        </b-form-group>

        <!-- ANCHOR info -->
        <AppCollapse
          type="card"
          class="border-dark rounded-8px"
        >
          <AppCollapseItem
            title
            class-header="card-header bg-blue-gradient"
          >
            <template #header>
              <span class="font-weight-bolder text-info">
                Khái niệm VIP và CIP
              </span>
              <small>Khi bạn phục vụ cho đối tượng khách hàng là VIP/CIP cần thông báo cho Hãng để được phục vụ tốt nhất. Đây là quy định bắt buộc!</small>
            </template>

            <div>
              <div class="text-danger">
                Khách VIP là những nhân vật quan trọng. Phân loại khách VIP theo quy định phục vụ hành khách của Vietnam Airlines như sau:
              </div>
              <li class="text-danger fw-600">
                Khách VIP 1:
              </li>
              <div class="text-justify">
                Tổng bí thư; Chủ tịch nước; phó Chủ tịch nước; Thủ tướng; phó Thủ tướng Chính phủ; Chủ tịch quốc hội; phó Chủ tịch quốc hội; Ủy viên bộ chính trị; Ủy viên Ban Bí thư và các đồng chí đã từng giữ chức vụ trên.
              </div>
              <li class="text-danger fw-600">
                Khách VIP 2:
              </li>
              <div class="text-justify">
                Bộ trưởng/cấp tương đương Bộ trưởng; Đại biểu Quốc hội trong nhiệm kỳ; Bí thư/Chủ tịch UBND/Chủ tịch HĐND cấp tỉnh, thành phố trực thuộc Trung ương; Bí thư Đảng uỷ khối ở Trung ương; Đại tướng;Ủy viên Ban chấp hành Trung ương đảng; Chủ nhiệm các uỷ ban của Quốc hội; Ủy viên Ủy ban Thường vụ Quốc hội; Ủy viên Hội đồng Quốc phòng và An ninh; Ủy viên Quân ủy Trung ương; Ủy viên Đảng ủy Công an Trung ương; Tổng tham mưu trưởng quân đội Nhân dân Việt Nam; Chủ nhiệm Tổng cục Chính trị Quân đội Nhân dân Việt.
              </div>
              <li class="text-danger fw-600">
                Khách VIP 3:
              </li>
              <div class="text-justify">
                Thứ trưởng/cấp tương đương Thứ trưởng; Phó Bí thư/Phó Chủ tịch UBND/Phó Chủ tịch HĐND cấp tỉnh/thành phố trực thuộc Trung ương; Phó Bí thư Đảng uỷ khối ở Trung ương; Phó chủ nhiệm các uỷ ban của Quốc hội; Phó Tổng tham mưu trưởng Quân đội nhân dân Việt Nam; Phó Chủ nhiệm Tổng cục Chính trị Quân đội Nhân dân Việt Nam; Chính ủy/ Phó Chính ủy quân khu/quân chủng/bộ đội biên phòng/cảnh sát biển/tư lệnh quân khu/quân chủng/bộ đội biên phòng/cảnh sát biển; Thiếu tướng các lực lượng vũ trang trở lên; Đại sứ đặc mệnh toàn quyền Việt Nam tại các nước, Đại sứ đặc mệnh toàn quyền các nước tại Việt Nam.
              </div>
              <div class="text-danger">
                Dịch vụ CIP thường dành cho các lãnh đạo doanh nhân quan trọng, đối tác thương mại của hãng.
              </div>
            </div>
          </AppCollapseItem>
        </AppCollapse>
      </div>

      <template #modal-footer="{close}">
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          size="md"
          variant="outline-secondary"
          class="center rounded-pill width-100"
          @click="close()"
        >
          {{ $t('reservation.close') }}
        </b-button>

        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          class="btn-gradient mt-lg-25  border-0"
          :disabled="!osiContent || !passengersSelected || !passengersSelected.length"
          pill
          @click="sendOSIFlightHandle"
        >
          <span class="align-middle">{{ $t('reservation.osi.title') }}</span>
        </b-button>
      </template>
    </b-modal>

    <ModalShowText
      :text="responseCommand"
      :title-modal="$t('reservation.osi.title')"
      :id-modal="idModal"
    />
  </div>
</template>

<script>
import {
  BModal, BButton, BFormInput, BFormGroup, BFormCheckbox, BFormCheckboxGroup,
} from 'bootstrap-vue'
import { ref, toRefs } from '@vue/composition-api'

import { removeAccentsUpperCaseFormatter } from '@core/comp-functions/forms/formatter-input'

import useReservationHandle from '@reservation/useReservationHandle'

export default {
  components: {
    BModal,
    BButton,
    BFormInput,
    BFormGroup,
    BFormCheckbox,
    BFormCheckboxGroup,
    vSelect: () => import('vue-select'),
    AppCollapse: () => import('@core/components/app-collapse/AppCollapse.vue'),
    AppCollapseItem: () => import('@core/components/app-collapse/AppCollapseItem.vue'),
    ModalShowText: () => import('@reservation/find-ticket/ModalShowText.vue'),
  },
  props: {
    bookingData: {
      type: Object,
      default: () => {},
    },
  },
  setup(props) {
    const { osiFlight } = useReservationHandle()
    const bookingData = toRefs(props).bookingData
    const osiContent = ref('')
    // const passengersAllSelected = ref(false)
    const passengersSelected = ref([])

    // function passengersToggleAll(checked) {
    //   passengersSelected.value = checked
    //     ? bookingData.value.paxLists.map(item => item.paxId).slice()
    //     : []
    // }
    // watch(passengersSelected, newValue => {
    //   if (newValue.length === 0) {
    //     passengersAllSelected.value = false
    //   } else if (newValue.length === bookingData.value.paxLists.map(item => item.paxId).length) {
    //     passengersAllSelected.value = true
    //   } else {
    //     passengersAllSelected.value = false
    //   }
    // })

    const osiCodeOptions = ref([
      { value: 'VVIP', text: 'Thông báo VIP' },
      { value: 'CCIP', text: 'Thông báo CIP' },
    ])
    const osiCode = ref()

    function handleShowModal() {
      passengersSelected.value = []
      // passengersAllSelected.value = false
      osiContent.value = ''
      osiCode.value = 'VVIP'
    }
    const responseCommand = ref('')
    const idModal = ref('add-osi')

    async function sendOSIFlightHandle() {
      this.$bvModal.show('modal-api-loading')
      const paxInfos = passengersSelected.value.map(item => ({
        firstName: item.firstName,
        lastName: item.lastName,
        title: item.title,
      }))
      const payload = {
        source: bookingData.value.source,
        agencyCode: bookingData.value.agency,
        pnrNumber: bookingData.value.bookingCode,
        osiCode: osiCode.value,
        remark: osiContent.value,
        paxInfos,
      }
      const res = await osiFlight(payload)
      responseCommand.value = res?.booking.length ? res?.booking.map(i => i?.data || '').join('\n') : ''
      this.$bvModal.hide('modal-api-loading')
      this.$bvModal.hide('modal-osi-flight')
      this.$bvModal.show(`modal-reservation-show-text${idModal.value}`)
    }
    return {
      sendOSIFlightHandle,
      osiContent,
      removeAccentsUpperCaseFormatter,
      handleShowModal,
      // passengersToggleAll,
      passengersSelected,
      // passengersAllSelected,
      osiCodeOptions,
      osiCode,
      responseCommand,
      idModal,
    }
  },
}
</script>
